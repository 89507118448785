<template>

    <div class="module h-full flex p-0">

        <div class="w-2/5 p-4 pl-8 flex flex-col">

            <div class="flex items-center mb-2 h-12">
                
                <div class="module-title self-center mr-auto">SL</div>
                <div class="text-font-gray mr-2">Sort By</div>
                <div class="w-48 mr-2">
                    <multiselect label="name" v-model="sortBy" :options="sortOptions" />
                </div>
                
            </div>

            <sl-column @loadingSl="setLoading" class="" :sortBy="sortBy" :sortOrder="sortBy.direction" />

        </div>

        <separator direction="vertical" />

        <smu-column :loadingSl="loadingSl" :sortBy="sortBy" :sortOrder="sortBy.direction" clas="w-3/5 p-4" />

    </div>

</template>

<script>

    const SlColumn = () => import('@/components/Desktop/ProjectsManagement/ProjectsSlColumn.vue')
    const SmuColumn = () => import('@/components/Desktop/ProjectsManagement/ProjectsSmuColumn')

    export default {
        components: {
            SlColumn,
            SmuColumn
        },
        data() {
            return {
                sortBy: { name: 'Name', value: 'name', direction: 'asc' },
                sortOptions: this.$optionsFilterProjects,
                sortOrder: { name: 'ASC', value: 'asc' },
                sortOrderOptions: [
                    { name: 'ASC', value: 'asc' },
                    { name:' DESC', value: 'desc' },
                ],
                loadingSl: true
            }
        },
        methods: {
            setLoading(val) { this.loadingSl = val }
        }
    }

</script>